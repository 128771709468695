import React from 'react';
import { Button } from '@digital-retail/journey-ui-kit';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  fetchRedirectStoreAsync,
  getFormIsValid,
} from '../../../../redux/MeasurementSettingsSlice';
import { ReactComponent as RightArrow } from '../../../../assets/icons/right-arrow.svg';

import ActionsBarDesktop from '../../../../components/ActionsBarDesktop';
import { setDigitalData } from '../../../../utils/adobeDTM/digitalData';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { previousPosition, setPosition } from '../../../../redux/StepperSlice';
import { COLORS } from '../../../../utils/constants/colors';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg';
import { getTenant } from '../../../../redux/ConfigSlice';
import { getCurtainColors } from '../../../../redux/CurtainSelectionSlice';

const MeasurementSettingActionsBarDesktop: React.FC = () => {
  const dispatch = useAppDispatch();
  const formIsValid = useAppSelector(getFormIsValid);
  const tenant = useAppSelector(getTenant);
  const colors = useAppSelector(getCurtainColors);

  const handleClickForward = () => {
    setDigitalData();
    dispatch(fetchRedirectStoreAsync({ tenant }));
  };

  const handleClickBackward = () => {
    if (colors.length > 0) {
      dispatch(previousPosition());
    } else {
      dispatch(setPosition(1));
    }
  };

  return (
    <div data-testid="measurement-setting-actions-bar-desktop">
      <ActionsBarDesktop>
        <Button
          iconPosition="end"
          data-testid="forward"
          disabled={!formIsValid}
          Icon={RightArrow}
          onClick={handleClickForward}
        >
          Confirmar
        </Button>
        <PillButton
          onClick={handleClickBackward}
          data-testid="backward"
          variant="ghost"
          iconPosition="start"
          Icon={LeftArrow}
          iconStyle={{
            fill: COLORS.primary,
          }}
          style={{
            color: COLORS.primary,
            marginTop: 12,
            paddingLeft: 0,
          }}
        >
          Selecciona un {colors.length > 0 ? 'color' : 'diseño'}
        </PillButton>
      </ActionsBarDesktop>
    </div>
  );
};

export default MeasurementSettingActionsBarDesktop;
