/* istanbul ignore file */
import URL from '../url';
import { FetchStylesProps } from '../../models/IFetchApiFacetsProps';
import customFetch from '../../utils/customFetch';
import { ICurtainStyle, IFetchResponse } from '../../models/ICurtainFacets';

export const fetchStyleOptions = async (props: FetchStylesProps) => {
  const { url, params } = URL.fetchCurtainStyles(props);
  const response = (await customFetch<IFetchResponse<ICurtainStyle[]>>(url, params)).parsedBody
    ?.data;
  return response ? response : [];
};
