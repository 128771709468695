import styled from 'styled-components';

export const StylePickerDesktopStyled = styled.div``;

export const OptionsListStyled = styled.ul`
  padding: 0;
  list-style: none;
`;

export const OptionStyled = styled.li`
  background-color: #f5f5f5;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-shadow: rgb(0 0 0 / 10%) 0 1px 3px;
  border-radius: 3px;
  height: 60px;
  cursor: pointer;
  transition: height 0.1s ease 0s;
  border: 1 solid transparent;
  &.active {
    border: 1px solid #d8d8d8;
    height: 88px;
  }
`;

export const TitleStyled = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
`;

export const LabelStyled = styled.div`
  font-size: 24px;
  line-height: 31px;
  font-weight: bold;
`;

export const ThumbnailStyled = styled.img`
  width: 80px;
  height: 60px;
  object-fit: cover;
  transition: all 0.1s ease 0s;

  ${OptionStyled}.active & {
    width: 100px;
    height: 90px;
  }
`;

export const OptionNameStyled = styled.div`
  margin-left: 32px;
  font-size: 14px;
  font-weight: 400;

  ${OptionStyled}.active & {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const LoadingStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 20px;
`;
