import React from 'react';
import { useAppSelector } from '../../app/hooks';
import {
  getSelectedCurtainStyle,
  getSelectedCurtainDesign,
  getSelectedCurtainCollection,
} from '../../redux/CurtainSelectionSlice';
import { BreadcrumbCurtainStyled } from './styles';

const BreadcrumbCurtain: React.FC = () => {
  const selectedCurtainStyle = useAppSelector(getSelectedCurtainStyle);
  const selectedCurtainDesign = useAppSelector(getSelectedCurtainDesign);
  const selectedCurtainCollection = useAppSelector(getSelectedCurtainCollection);

  return (
    <BreadcrumbCurtainStyled data-testid="breadcrumb-curtain">
      <span>{selectedCurtainStyle?.label}</span> <span>{selectedCurtainDesign?.label}</span>{' '}
      <span>{selectedCurtainCollection?.label}</span>
    </BreadcrumbCurtainStyled>
  );
};

export default BreadcrumbCurtain;
