import React from 'react';
import { Modal } from '@digital-retail/journey-ui-kit';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  getInformationHowToMeasureModalIsShowing,
  hideInformationHowToMeasureModal,
} from '../../../../redux/MeasurementSettingsSlice';
import { InformationHowToMeasureModalStyled } from './styles';

const InformationHowToMeasureModal: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(getInformationHowToMeasureModalIsShowing);

  const handleClickCloseModal = () => {
    dispatch(hideInformationHowToMeasureModal());
  };

  return (
    <Modal open={open} size="md" onClose={handleClickCloseModal}>
      <InformationHowToMeasureModalStyled data-testid="information-how-to-measure-modal">
        {children}
      </InformationHowToMeasureModalStyled>
    </Modal>
  );
};

export default InformationHowToMeasureModal;
