/* istanbul ignore file */

import { IBreakPoint } from '../../models/IBreakPoint';

export const BREAKPOINTS: IBreakPoint = {
  mobile: 320,
  tablet: 720,
  desktop: 1024,
  superDesktop: 1280,
};
