import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  LabelStyled,
  OptionNameStyled,
  OptionsListStyled,
  OptionStyled,
  TitleStyled,
  LoadingStyled,
  ActionBackwardStyled,
} from './styles';
import {
  ThumbnailStyled as DesignThumbnailStyled,
  OptionsListStyled as DesignOptionsListStyled,
  OptionStyled as DesignOptionStyled,
  OptionNameStyled as DesignOptionNameStyled,
} from '../CurtainDesignPickerDesktop/styles';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg';
import { Button, PillButton, Spinner } from '@digital-retail/journey-ui-kit';
import { nextPosition } from '../../../../redux/StepperSlice';
import {
  fetchCurtainCollectionsAsync,
  getCurtainCollections,
  getCurtainCollectionStatus,
  getSelectedCurtainStyle,
  getSelectedCurtainDesign,
  getSelectedCurtainCollection,
  selectCurtainCollection,
} from '../../../../redux/CurtainSelectionSlice';
import { setStep } from '../../../../redux/CurtainDesignSelectionSlice';
import { COLORS } from '../../../../utils/constants/colors';
import { setDigitalData } from '../../../../utils/adobeDTM/digitalData';
import ActionsBarDesktop from '../../../../components/ActionsBarDesktop';
import { getTenant } from '../../../../redux/ConfigSlice';
import { ICurtainCollection } from '../../../../models/ICurtainFacets';

const CurtainVariantPickerDesktop: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedCurtainStyle = useAppSelector(getSelectedCurtainStyle);
  const selectedCurtainDesign = useAppSelector(getSelectedCurtainDesign);
  const selectedCurtainCollection = useAppSelector(getSelectedCurtainCollection);
  const collections = useAppSelector(getCurtainCollections);
  const curtainCollectionStatus = useAppSelector(getCurtainCollectionStatus);
  const tenant = useAppSelector(getTenant);

  useEffect(() => {
    setDigitalData();
    if (collections.length === 0) {
      dispatch(fetchCurtainCollectionsAsync({ tenant }));
    }
  }, []);

  const handleClickConfirmCurtainType = () => {
    dispatch(nextPosition());
  };

  const handleClick = (selectedCollection: ICurtainCollection) => {
    dispatch(selectCurtainCollection(selectedCollection));
  };

  const handleClickGoToTypeSelection = () => {
    dispatch(setStep('curtainDesignSelection'));
  };

  return (
    <div data-testid="curtain-variant-picker-desktop">
      <TitleStyled>
        {selectedCurtainStyle?.label} {selectedCurtainDesign?.label}
      </TitleStyled>
      {selectedCurtainDesign && (
        <div style={{ marginBottom: '30px' }}>
          <LabelStyled>Selecciona el diseño de cortina</LabelStyled>
          <DesignOptionsListStyled>
            <DesignOptionStyled>
              <DesignThumbnailStyled
                src={selectedCurtainDesign.image.thumb}
                alt={selectedCurtainDesign.label}
              />
              <DesignOptionNameStyled>{selectedCurtainDesign.label}</DesignOptionNameStyled>
              <ActionBackwardStyled
                onClick={handleClickGoToTypeSelection}
                data-testid="change-design"
              >
                Cambiar
              </ActionBackwardStyled>
            </DesignOptionStyled>
          </DesignOptionsListStyled>
        </div>
      )}

      <LabelStyled>Selecciona un tipo</LabelStyled>
      {curtainCollectionStatus === 'loading' && (
        <LoadingStyled data-testid="loading">
          <Spinner size="md" />
        </LoadingStyled>
      )}
      {curtainCollectionStatus === 'idle' && (
        <OptionsListStyled data-testid="options-list">
          {collections.map((collection) => (
            <OptionStyled
              key={collection.id}
              className={collection.id === selectedCurtainCollection?.id ? 'active' : ''}
              onClick={() => handleClick(collection)}
            >
              <OptionNameStyled>{collection.label}</OptionNameStyled>
            </OptionStyled>
          ))}
        </OptionsListStyled>
      )}

      <ActionsBarDesktop>
        <Button
          data-testid="confirm-curtain-selected"
          onClick={handleClickConfirmCurtainType}
          disabled={selectedCurtainCollection === null}
        >
          Continuar
        </Button>
        <PillButton
          onClick={handleClickGoToTypeSelection}
          data-testid="back"
          variant="ghost"
          iconPosition="start"
          Icon={LeftArrow}
          iconStyle={{
            fill: COLORS.primary,
          }}
          style={{
            color: COLORS.primary,
            marginTop: 12,
            paddingLeft: 0,
          }}
        >
          Selecciona un diseño
        </PillButton>
      </ActionsBarDesktop>
    </div>
  );
};

export default CurtainVariantPickerDesktop;
