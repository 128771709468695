import styled from 'styled-components';

export const StylePickerMobileStyled = styled.div``;

export const OptionsWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;
  gap: 10px;
`;

export const OptionsListStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  padding: 10px 15px;
  margin: -5px;

  & > * {
    margin: 5px;
  }
`;

export const OptionStyled = styled.div``;

export const LoadingStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 9px;
`;
