
export const findClosestLargestMeasure = (
  values: string[],
  measure: number,
): string => {
  let closestDifference = Infinity;
  let closestValue;
  for (const value of values) {
    const numericValue = parseInt(value);
    const difference = Math.abs(measure - numericValue);
    if (difference < closestDifference) {
      closestDifference = difference;
      closestValue = value;
    }
  }
  return closestValue;
};