import React from 'react';
import ActionsBarMobile, { ActionBarMobile } from '../../../../components/ActionsBarMobile';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { nextPosition, previousPosition, setPosition } from '../../../../redux/StepperSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { ReactComponent as RightArrow } from '../../../../assets/icons/right-arrow.svg';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg';
import { COLORS } from '../../../../utils/constants/colors';
import {
  getSelectedCurtainColor,
  getSelectedCurtainCollection,
  getCurtainDesigns,
} from '../../../../redux/CurtainSelectionSlice';

const CurtainColorSelectionActionsBarMobile: React.FC = () => {
  const dispatch = useAppDispatch();
  const curtainColorSelected = useAppSelector(getSelectedCurtainColor);
  const curtainCollectionSelected = useAppSelector(getSelectedCurtainCollection);
  const designs = useAppSelector(getCurtainDesigns);

  const handleClickBackward = () => {
    if (designs.length === 0) {
      dispatch(setPosition(0));
    } else {
      dispatch(previousPosition());
    }
  };

  const handleClickForward = () => {
    dispatch(nextPosition());
  };

  return (
    <div data-testid="curtain-color-selection-actions-bar-mobile">
      <ActionsBarMobile>
        <ActionBarMobile>
          <PillButton
            onClick={handleClickForward}
            variant="ghost"
            iconPosition="end"
            Icon={RightArrow}
            data-testid="forward"
            disabled={!curtainColorSelected}
          >
            Confirmar
          </PillButton>
        </ActionBarMobile>
        <ActionBarMobile>
          <PillButton
            onClick={handleClickBackward}
            variant="ghost"
            iconPosition="start"
            Icon={LeftArrow}
            data-testid="backward"
            style={{
              color: COLORS.gray20,
            }}
          >
            Selecciona un{' '}
            {designs.length === 0
              ? 'estilo'
              : curtainCollectionSelected === null
              ? 'diseño'
              : 'tipo'}
          </PillButton>
        </ActionBarMobile>
      </ActionsBarMobile>
    </div>
  );
};

export default CurtainColorSelectionActionsBarMobile;
