import React, { memo } from 'react';
import { getCurrentPosition } from '../../redux/StepperSlice';
import { views } from './views';
import { useAppSelector } from '../../app/hooks';
import Layout from '../Layout';
import CurtainImage from '../CurtainImage';
import { BREAKPOINTS } from '../../utils/constants/breakpoints';
import { useWindowSize } from '../../hooks/useWindowSize';
import { MeasurementSettingsPageMobile } from '../../features/MeasurementSettingsPage/components/MeasurementSettingsPageMobile';

const CustomRouter: React.FC = () => {
  const { width } = useWindowSize();
  const currentPositionStep = useAppSelector(getCurrentPosition);
  const size = width && width > BREAKPOINTS.tablet ? 'desktop' : 'mobile';

  const getMain = (): React.ReactNode => {
    return (
      <>
        {size === 'mobile' && currentPositionStep === 3 ? (
          <MeasurementSettingsPageMobile />
        ) : (
          <CurtainImage size={size} currentPositionStep={currentPositionStep} />
        )}
      </>
    );
  };

  const getSidebar = (): React.ReactNode => {
    const Component = views[currentPositionStep]?.sidebar;

    if (Component) {
      return <Component />;
    }
  };

  return (
    <div data-testid="custom-router">
      <Layout withStepper={true} sidebar={getSidebar()}>
        {getMain()}
      </Layout>
    </div>
  );
};

export default memo(CustomRouter);
