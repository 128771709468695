import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const WelcomePageStyled = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;

  display: grid;

  grid-template-columns: 1fr 426px;
  grid-template-rows: max-content 1fr;

  grid-template-areas:
    'jumbotron header'
    'jumbotron body';

  ${mediaQuery.mobileOnly(`
    top: 40px;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr max-content;

    grid-template-areas:
      'header'
      'jumbotron'
      'body';
  `)}
`;

export const JumbotronStyled = styled.img`
  grid-area: jumbotron;
  object-fit: cover;
  height: 520px;
  max-height: 520px;
  width: 100%;
`;

export const HeaderStyled = styled.div`
  grid-area: header;
  margin: 20px 60px 0 30px;
  font-size: 20px;
  line-height: 26px;

  ${mediaQuery.mobileOnly(`
    margin: 0px 15px 20px;
    font-size: 17px;
    line-height: 22px;
  `)}
`;

export const BodyStyled = styled.div`
  grid-area: body;
  margin: 30px 17px 0 18px;
  font-size: 14px;
  line-height: 18px;

  ${mediaQuery.mobileOnly(`
    margin: 20px 15px 20px;
  `)}
`;

export const StartButtonStyled = styled.div`
  ${mediaQuery.mobileOnly(`
    & > * {
      width: 100%;
    }
  `)}
`;

export const DescriptionStyled = styled.p`
  margin-right: 60px;
`