import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

type WelcomePageReducer = {
  serviceModalIsShowing: boolean;
};

const initialState: WelcomePageReducer = {
  serviceModalIsShowing: false,
};

export const welcomePageSlice = createSlice({
  name: 'welcomePage',
  initialState,
  reducers: {
    showServiceModal: (state) => {
      state.serviceModalIsShowing = true;
    },
    hideServiceModal: (state) => {
      state.serviceModalIsShowing = false;
    },
  },
});

export const { showServiceModal, hideServiceModal } = welcomePageSlice.actions;

export const getServiceModalIsShowing = (state: RootState) =>
  state.welcomePage.serviceModalIsShowing;

export default welcomePageSlice.reducer;
