/* istanbul ignore file */
import {
  FetchCollectionsProps,
  FetchColorsProps,
  FetchDesignsProps,
  FetchMeasurementsProps,
  FetchStoreUrlProps,
  FetchStylesProps,
} from '../../models/IFetchApiFacetsProps';
import { BFF_API } from '../../utils/constants/envVariables';
import { generateSearchParams } from './utils';

export default {
  fetchCurtainStyles: ({ tenant, ...rest }: FetchStylesProps) => {
    const searchParams = generateSearchParams(rest, tenant);
    return {
      url: `${BFF_API}/curtains/${tenant}/styles?${searchParams}`,
      params: { method: 'GET' },
    };
  },
  fetchCurtainDesigns: ({ tenant, ...rest }: FetchDesignsProps) => {
    const searchParams = generateSearchParams(rest, tenant);
    return {
      url: `${BFF_API}/curtains/${tenant}/designs?${searchParams}`,
      params: { method: 'GET' },
    };
  },
  fetchCurtainCollections: ({ tenant, ...rest }: FetchCollectionsProps) => {
    const searchParams = generateSearchParams(rest, tenant);
    return {
      url: `${BFF_API}/curtains/${tenant}/collections?${searchParams}`,
      params: { method: 'GET' },
    };
  },
  fetchCurtainColors: ({ tenant, ...rest }: FetchColorsProps) => {
    const searchParams = generateSearchParams(rest, tenant);
    return {
      url: `${BFF_API}/curtains/${tenant}/colors?${searchParams}`,
      params: { method: 'GET' },
    };
  },
  fetchCurtainMeasurements: ({ tenant, ...rest }: FetchMeasurementsProps) => {
    const searchParams = generateSearchParams(rest, tenant);
    return {
      url: `${BFF_API}/curtains/${tenant}/measurements?${searchParams}`,
      params: { method: 'GET' },
    };
  },
  fetchCurtainStoreUrl: ({ tenant, ...rest }: FetchStoreUrlProps) => {
    const searchParams = generateSearchParams(rest, tenant);
    return {
      url: `${BFF_API}/curtains/${tenant}/url?${searchParams}`,
      params: { method: 'GET' },
    };
  },
};
