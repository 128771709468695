import { ReactComponent as ClockIcon } from '../assets/images/WelcomePage/IconsModal/clock.svg';
import { ReactComponent as DeliveryIcon } from '../assets/images/WelcomePage/IconsModal/home-delivery.svg';
import { ReactComponent as DoorsIcon } from '../assets/images/WelcomePage/IconsModal/doors-windows.svg';

export const MOCK_SERVICES = [
  {
    id: 0,
    name: 'clock',
    Image: ClockIcon,
    description: 'Elige tu cortina y te contactaremos en',
    hightlighed: '48 hrs hábiles.'
  },
  {
    id: 1,
    name: 'delivery',
    Image: DeliveryIcon,
    description:
      'Vamos a tu casa para ',
    hightlighed: 'verificar las medidas.'

  },
  {
    id: 2,
    name: 'doors-windows',
    Image: DoorsIcon,
    description: 'Fabricamos y realizamos la instalación por ti.',
    hightlighed: ''
  }
] as const;
