import React from 'react';
import MediaComponent from '../MediaComponent';
import StepperDesktop from '../StepperDesktop';
import StepperMobile from '../StepperMobile';
import {
  ContainerStyled,
  HeaderStyled,
  LayoutStyled,
  MainStyled,
  SidebarContentStyled,
  SideBarStyled,
} from './styles';

type LayoutProps = {
  sidebar: React.ReactNode;
  withStepper: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children: main, sidebar, withStepper }) => {
  return (
    <LayoutStyled data-testid="layout">
      <HeaderStyled data-testid="header">
        {withStepper && (
          <MediaComponent from="tablet" style={{ width: '100%' }}>
            <StepperDesktop />
          </MediaComponent>
        )}
      </HeaderStyled>
      <ContainerStyled data-testid="container">
        <MainStyled data-testid="main">{main}</MainStyled>
        <SideBarStyled data-testid="sidebar">
          {withStepper && (
            <MediaComponent from="mobile" to="tablet">
              <StepperMobile />
            </MediaComponent>
          )}
          <SidebarContentStyled>{sidebar}</SidebarContentStyled>
        </SideBarStyled>
      </ContainerStyled>
    </LayoutStyled>
  );
};

export default Layout;
