import React, { useEffect, useRef, useState } from 'react';
import { BoxContentStyled, BoxStyled, GradientStyled } from './styles';
import clsx from 'clsx';

const delta = 4;

type BoxProps = {
  maxHeight?: string;
};

const Box: React.FC<BoxProps> = ({ children, maxHeight }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [showGradientTop, setShowGradientTop] = useState(false);
  const [showGradientBottom, setShowGradientBottom] = useState(true);

  const updateState = () => {
    if (ref.current) {
      const { scrollTop, offsetHeight, scrollHeight } = ref.current;
      setShowGradientTop(!(scrollTop <= delta));
      setShowGradientBottom(!(offsetHeight + scrollTop >= scrollHeight - delta));
    }
  };

  useEffect(() => {
    updateState();
  }, []);

  return (
    <BoxStyled
      ref={ref}
      onScroll={() => {
        updateState();
      }}
      onClick={() => {
        setTimeout(() => {
          updateState();
        }, 1);
      }}
      style={{ maxHeight }}
    >
      <GradientStyled className={clsx('top', { show: showGradientTop })} />
      <BoxContentStyled>{children}</BoxContentStyled>
      <GradientStyled className={clsx('bottom', { show: showGradientBottom })} />
    </BoxStyled>
  );
};

export default Box;
