/* istanbul ignore file */
import URL from '../url';
import customFetch from '../../utils/customFetch';
import { FetchStoreUrlProps } from '../../models/IFetchApiFacetsProps';
import { ICurtainStoreUrl, IFetchResponse } from '../../models/ICurtainFacets';

export const fetchStoreUrl = async (props: FetchStoreUrlProps) => {
  const { url, params } = URL.fetchCurtainStoreUrl(props);
  const response = (await customFetch<IFetchResponse<ICurtainStoreUrl>>(url, params)).parsedBody
    ?.data;
  return response?.url ? response.url : '';
};
