import { useEffect } from 'react';
import MediaComponent from '../../components/MediaComponent';
import { setDigitalData } from '../../utils/adobeDTM/digitalData';
import InformationHowToMeasure from './components/InformationHowToMeasure';
import MeasurementSettingActionsBarDesktop from './components/MeasurementSettingActionsBarDesktop';
import MeasurementSettingsActionBarMobile from './components/MeasurementSettingsActionBarMobile';
import MeasurementSettingForm from './components/MeasurementSettingsForm';
import { MeasurementSettingsSidebarStyled } from './styles';
import ModalInformationHowToMeasure from './components/InformationHowToMeasureModal';

const MeasurementSettingsPage: React.FC = () => {
  useEffect(() => {
    setDigitalData();
  }, []);

  return (
    <>
      <MediaComponent from="mobile" to="tablet">
        <MeasurementSettingsActionBarMobile />
      </MediaComponent>
      <MediaComponent from="tablet">
        <MeasurementSettingsSidebarStyled data-testid="measurement-settings-sidebar">
          <MeasurementSettingForm />
          <MeasurementSettingActionsBarDesktop />
          <ModalInformationHowToMeasure>
            <InformationHowToMeasure />
          </ModalInformationHowToMeasure>
        </MeasurementSettingsSidebarStyled>
      </MediaComponent>
    </>
  );
};

export default MeasurementSettingsPage;
