import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils';

export const MeasurementSettingsStyled = styled.div`
  ${mediaQuery.mobileOnly(`
    padding: 20px;
    padding-bottom: 60px;

    &:before {
      content: '';
      display: block;
      position: fixed;
      bottom: 40px;
      left: 0;
      right: 0;
      height: 94px;

      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.65) 100%
      );
    }
  `)}
`;
