import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import StepperSlice from '../redux/StepperSlice';
import WelcomePageSlice from '../redux/WelcomePageSlice';
import CurtainDesignSelectionSlice from '../redux/CurtainDesignSelectionSlice';
import MeasurementSettingsSlice from '../redux/MeasurementSettingsSlice';
import CurtainSelectionSlice from '../redux/CurtainSelectionSlice';
import { RecursivePartial } from '../utils/recursivePartialType';
import ConfigSlice from '../redux/ConfigSlice';

export const rootReducer = {
  config: ConfigSlice,
  welcomePage: WelcomePageSlice,
  stepper: StepperSlice,
  curtainDesignSelection: CurtainDesignSelectionSlice,
  measurementSettings: MeasurementSettingsSlice,
  curtainSelection: CurtainSelectionSlice,
};

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === 'development',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type InitialRootState = RecursivePartial<RootState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
