import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

type ConfigState = {
  tenant: string;
};
const initialState: ConfigState = {
  tenant: '',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTenant: (state, action: PayloadAction<ConfigState['tenant']>) => {
      state.tenant = action.payload;
    },
  },
});

//Export actions
export const { setTenant } = configSlice.actions;

//Definición de selectores
export const getTenant = (state: RootState) => state.config.tenant;

export default configSlice.reducer;
