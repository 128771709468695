import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils';

export const InformationHowToMeasureStyled = styled.div`
  ${mediaQuery.mobileOnly(`
    padding-bottom: 50px;
  `)}
`;

export const TitleStyled = styled.div`
  font-size: 17px;
  line-height: 22px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const SectionStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content 1fr;
  gap: 0 10px;
  margin-bottom: 30px;
  align-items: start;

  grid-template-areas:
    'imagen subTitle'
    'imagen instructions';

  ${mediaQuery.mobileOnly(`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content min-content;
    grid-template-areas:
      'subTitle'
      'instructions'
      'imagen';
  `)}
`;

export const SubTitleStyled = styled.div`
  grid-area: subTitle;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
`;

export const InstructionStyled = styled.p`
  grid-area: instructions;
  font-size: 14px;
`;

export const ImageStyled = styled.img`
  grid-area: imagen;
  width: 276px;

  ${mediaQuery.mobileOnly(`
    justify-self: center; 
  `)}
`;
