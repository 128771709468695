import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const LayoutStyled = styled.div`
  padding: 0;
`;

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${mediaQuery.mobileOnly(`
    background-color: #F5F5F5;
    top: 0;
    position: sticky;
    z-index: 1;
  `)}
`;

export const ContainerStyled = styled.div`
  width: 100%;

  ${mediaQuery.tabletToUp(`
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: 1fr;
    grid-template-areas: 'main sidebar';
    max-height: 520px;
  `)}
`;

export const MainStyled = styled.div`
  ${mediaQuery.tabletToUp(`
    grid-area: main;
    max-height: 520px;
  `)}
`;

export const SideBarStyled = styled.div`
  ${mediaQuery.tabletToUp(`
    grid-area: sidebar;
    max-height: 520px;
  `)}

  ${mediaQuery.mobileOnly(`
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    min-height: 0px;
  `)}
`;

export const SidebarContentStyled = styled.div`
  background-color: #ffffff;
`;
