import React, { useEffect } from 'react';
import MediaComponent from '../../components/MediaComponent';
import StylePickerMobile from './components/StylePickerMobile';
import StylePickerDesktop from './components/StylePickerDesktop';
import { CurtainStyleSelectionPageStyled } from './styles';
import { setDigitalData } from '../../utils/adobeDTM/digitalData';

const CurtainStyleSelectionPage: React.FC = () => {
  useEffect(() => {
    setDigitalData();
  }, []);
  return (
    <CurtainStyleSelectionPageStyled data-testid="style-selection-sidebar">
      <MediaComponent from="mobile" to="tablet">
        <StylePickerMobile />
      </MediaComponent>
      <MediaComponent from="tablet">
        <StylePickerDesktop />
      </MediaComponent>
    </CurtainStyleSelectionPageStyled>
  );
};

export default CurtainStyleSelectionPage;
