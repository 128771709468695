import { IMedia } from '../../../models/IMedia';
import { BREAKPOINTS } from '../../../utils/constants/breakpoints';

type MediaComponentValidatorProps = {
  from?: IMedia;
  to?: IMedia;
  width?: number;
};
export const mediaComponentValidator = ({
  from,
  to,
  width,
}: MediaComponentValidatorProps): boolean => {
  const breakpointFrom = BREAKPOINTS[from || ''];
  const breakpointTo = BREAKPOINTS[to || ''];

  if (width === undefined) {
    return false;
  }

  try {
    if (breakpointFrom !== undefined && breakpointTo !== undefined) {
      if (breakpointFrom >= breakpointTo) {
        throw `"From" is greater than or equal to "To". ${JSON.stringify({
          from,
          to,
        })}`;
      }

      if (width < breakpointFrom || breakpointTo < width) {
        return false;
      }
    } else if (breakpointFrom !== undefined) {
      if (width < breakpointFrom) {
        return false;
      }
    } else {
      if (width >= breakpointTo) {
        return false;
      }
    }

    return true;
  } catch (reason) {
    // eslint-disable-next-line no-console
    console.warn(reason);
    return false;
  }
};
