import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const CurtainImageWrapperStyled = styled.div`
  position: relative;
  max-height: 520px;

  ${mediaQuery.mobileOnly(`
    height: calc(100vh - 40px);
  `)}
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 520px;

  ${mediaQuery.mobileOnly(`
    height: calc(100vh - 50px);
  `)}
`;

export const InformationBoxStyled = styled.div`
  position: absolute;
  color: #000;
  background-color: #fff;
  border-radius: 3px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: auto;

  ${mediaQuery.mobileOnly(`
    top: 10px;
    left: 15px;
    right: 15px;
  `)}

  ${mediaQuery.tabletToUp(`
    right: 15px;
    bottom: 15px;
    width: 433px;
  `)}
`;

export const NameStyled = styled.div`
  font-size: 17px;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 5px;
`;

export const DescriptionStyled = styled.div`
  font-size: 12px;
  line-height: 16px;
`;
