import React from 'react';
import { ActionsBarDesktopStyled } from './styles';

const ActionsBarDesktop: React.FC = ({ children }) => {
  return (
    <ActionsBarDesktopStyled data-testid="actions-bar-desktop">{children}</ActionsBarDesktopStyled>
  );
};

export default ActionsBarDesktop;
