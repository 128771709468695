/* istanbul ignore file */

import { FetchColorsProps } from '../../models/IFetchApiFacetsProps';
import URL from '../url';
import customFetch from '../../utils/customFetch';
import { ICurtainColor, IFetchResponse } from '../../models/ICurtainFacets';

export const fetchColorOptions = async (props: FetchColorsProps) => {
  const { url, params } = URL.fetchCurtainColors(props);
  const response = (await customFetch<IFetchResponse<ICurtainColor[]>>(url, params)).parsedBody
    ?.data;
  return response ? response : [];
};
