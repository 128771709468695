import React from 'react';
import { ActionsBarMobileStyled } from './styles';

export const ActionBarMobile: React.FC = ({ children }) => {
  return <div>{children}</div>;
};

const ActionsBarMobile: React.FC = ({ children }) => {
  return (
    <ActionsBarMobileStyled data-testid="actions-bar-mobile">{children}</ActionsBarMobileStyled>
  );
};

export default ActionsBarMobile;
