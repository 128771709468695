import styled from 'styled-components';
import { ColorIndicatorStyledProps } from '..';
import { mediaQuery } from '../../../../../utils';

export const CurtainColorPickerMobileStyled = styled.div``;

export const OptionsListStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  overflow-x: auto;
  background-color: #ffffff;

  margin: 0 -10px;

  ${mediaQuery.mobileOnly(`
    flex-flow: row nowrap;
    margin: 0px;
  `)}
`;

export const OptionStyled = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px;
  grid-template-areas:
    'indicador'
    'nombre';
  justify-items: center;
  align-items: start;
  box-sizing: border-box;
  width: 85px;
  min-width: 85px;
  height: 117px;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  margin: 7px;
  cursor: pointer;

  &.active {
    background-color: #f5f5f5;
  }

  ${mediaQuery.mobileOnly(`
     margin: 0px;
     height: 100px;
     border-radius: 0;
  `)}
`;

export const ColorIndicatorStyled = styled.div<ColorIndicatorStyledProps>`
  background: ${({ color }) => color};
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid #808080;
  margin-top: 15px;
  margin-bottom: 10px;

  ${OptionStyled}.active & {
    border: 2px solid #0072ce;
  }

  ${mediaQuery.mobileOnly(`
    height: 30px;
    width: 30px;
  `)}
`;

export const ColorNameStyled = styled.div`
  text-align: center;
  width: 80%;
  height: calc(100% - 10px);
  font-weight: 400;
  color: #333333;
  font-size: 12px;
  line-height: 16px;

  ${OptionStyled}.active & {
    font-weight: 700;
  }
`;

export const LoadingStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 9px;
`;

export const TitleStyled = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 20px;
`;
