import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { getSelectedCurtainStyle } from '../../../../redux/CurtainSelectionSlice';
import {
  ImageStyled,
  InformationHowToMeasureStyled,
  InstructionStyled,
  SectionStyled,
  SubTitleStyled,
  TitleStyled,
} from './styles';

const InformationHowToMeasure: React.FC = () => {
  const selectedCurtainStyle = useAppSelector(getSelectedCurtainStyle);

  if (selectedCurtainStyle === null) {
    return null;
  }

  return (
    <InformationHowToMeasureStyled data-testid="information-how-to-measure">
      <div>
        <TitleStyled>¿Cómo medir tus {selectedCurtainStyle?.pluralLabel}?</TitleStyled>
        {selectedCurtainStyle?.instructions.map((i, index) => (
          <SectionStyled key={index} data-testid="instruction-how-to-measure">
            <SubTitleStyled>{i.title}</SubTitleStyled>
            <InstructionStyled
              dangerouslySetInnerHTML={{ __html: i.instruction }}
            ></InstructionStyled>
            <ImageStyled src={i.image} />
          </SectionStyled>
        ))}
      </div>
    </InformationHowToMeasureStyled>
  );
};

export default InformationHowToMeasure;
