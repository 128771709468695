import React from 'react';
import { StepperDesktop } from '@digital-retail/journey-ui-kit';
import { StepperDesktopStyled } from './styles';
import { getCurrentPosition, setPosition } from '../../redux/StepperSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { views } from '../CustomRouter/views/index';

const Stepper = () => {
  const position = useAppSelector(getCurrentPosition);
  const dispatch = useAppDispatch();

  const comeBackTo = (newPosition: number) => {
    dispatch(setPosition(newPosition));
  };

  return (
    <StepperDesktopStyled data-testid="stepper-desktop">
      <StepperDesktop
        currentStep={position + 1}
        labels={views.map((item) => item.title)}
        onClick={(stepIndex: number) => comeBackTo(stepIndex)}
      />
    </StepperDesktopStyled>
  );
};

export default Stepper;
