import { store } from '../../../app/store';
import { views } from '../../../components/CustomRouter/views';
import { IDigitalData } from '../../../models/IDigitalData';
import { CurtainSelectionReducer } from '../../../redux/CurtainSelectionSlice';
import { MeasurementSettingsReducer } from '../../../redux/MeasurementSettingsSlice';

export const updateDigitalData = (payload: IDigitalData) => {
  window.digitalData = Object.assign({}, window?.digitalData || {}, payload);
};

type GetCurtainSelectionProps = {
  selectedCurtainStyle: CurtainSelectionReducer['selectedCurtainStyle'];
  selectedCurtainColor: CurtainSelectionReducer['selectedCurtainColor'];
  selectedCurtainDesign: CurtainSelectionReducer['selectedCurtainDesign'];
  selectedCurtainVariant: CurtainSelectionReducer['selectedCurtainCollection'];
  windowHeight: MeasurementSettingsReducer['windowHeight'];
  windowWidth: MeasurementSettingsReducer['windowWidth'];
};
export const getCurtainSelection = ({
  selectedCurtainStyle,
  selectedCurtainColor,
  selectedCurtainDesign,
  selectedCurtainVariant,
  windowHeight,
  windowWidth,
}: GetCurtainSelectionProps): string => {
  const result: any[] = [];

  if (selectedCurtainStyle !== null) {
    result.push(selectedCurtainStyle.label);
  }

  if (selectedCurtainDesign !== null) {
    const aux: any[] = [];
    aux.push(selectedCurtainDesign.label);
    if (selectedCurtainVariant !== null) {
      aux.push(selectedCurtainVariant.label);
    }
    result.push(aux.join(' - '));
  }

  if (selectedCurtainColor !== null) {
    result.push(selectedCurtainColor.label);
  }

  if (windowHeight !== '') {
    const aux: any[] = [];
    aux.push(windowHeight);
    if (windowWidth !== '') {
      aux.push(windowWidth);
    }
    result.push(aux.join('x'));
  }

  return result.join(': ');
};

type GetPageNamePros = {
  pathname: string;
  position: number;
};
export const getPageName = ({ pathname, position }: GetPageNamePros): string => {
  return pathname === '/'
    ? 'journeys tools: cortinas'
    : `journeys tools: cortinas: paso ${position + 1}: ${views[position].title}`;
};

export const setDigitalData = () => {
  const {
    curtainSelection: {
      selectedCurtainStyle,
      selectedCurtainColor,
      selectedCurtainDesign,
      selectedCurtainCollection,
    },
    stepper: { position },
    measurementSettings: { windowHeight, windowWidth },
  } = store.getState();

  const { href: url, pathname } = global.window.location;

  const digitalData: IDigitalData = {
    page: {
      pageName: getPageName({ pathname, position }),
      pageType: 'journeys tools',
      catalyst: 'journeys tools',
      siteSection: 'Journeys Tools',
      subSection: 'Journeys Tools: Cortinas',
      url,
      curtainselection: getCurtainSelection({
        selectedCurtainStyle,
        selectedCurtainColor,
        selectedCurtainDesign,
        selectedCurtainVariant: selectedCurtainCollection,
        windowHeight,
        windowWidth,
      }),
    },
  };

  updateDigitalData(digitalData);
};
