import styled from 'styled-components';

export const ActionsBarMobileStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;

  justify-content: space-between;
  align-content: center;
  align-items: center;

  height: 41px;
  padding: 0 5px;
  background-color: #ffffff;
  border-top: 1px solid #bfbfbf;
`;
