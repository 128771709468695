import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { stepperSlice, StepperStateReducer } from '../StepperSlice';

export type ICurtainDesignSelectionSubStep =
  | 'curtainDesignSelection'
  | 'curtainCollectionSelection';

type CurtainDesignSelectionReducer = {
  curtainDesignSelectionSubStep: ICurtainDesignSelectionSubStep;
};

const initialState: CurtainDesignSelectionReducer = {
  curtainDesignSelectionSubStep: 'curtainDesignSelection',
};

export const curtainTypeSelectionSlice = createSlice({
  name: 'curtainDesignSelection',
  initialState,
  reducers: {
    resetStep: (state) => {
      state.curtainDesignSelectionSubStep = initialState.curtainDesignSelectionSubStep;
    },
    setStep: (
      state,
      action: PayloadAction<CurtainDesignSelectionReducer['curtainDesignSelectionSubStep']>
    ) => {
      state.curtainDesignSelectionSubStep = action.payload;
    },
  },
  extraReducers: {
    // @ts-expect-error in TypeScript
    [stepperSlice.actions.setPosition]: (
      state: CurtainDesignSelectionReducer,
      action: PayloadAction<StepperStateReducer['position']>
    ) => {
      if (action.payload === 1) {
        state.curtainDesignSelectionSubStep = 'curtainDesignSelection';
      }
    },
  },
});

export const { resetStep, setStep } = curtainTypeSelectionSlice.actions;

export const getCurtainDesignSelectionSubStep = (state: RootState) =>
  state.curtainDesignSelection.curtainDesignSelectionSubStep;

export default curtainTypeSelectionSlice.reducer;
