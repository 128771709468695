import Cookies from 'js-cookie';
import { getBUFromTenant } from './helpers';
import { BUSINESS_UNITS, COOKIE_NAMES, DEFAULT_COOKIE_VALUES } from './constants/constants';

export const getZones = (tenant: string) => {
  let parsedZones = '';
  const bu = getBUFromTenant(tenant);
  const cookieName = COOKIE_NAMES[bu].ZONES;
  if (bu === BUSINESS_UNITS.FA) {
    const zones = Cookies.get(cookieName) || DEFAULT_COOKIE_VALUES[tenant][cookieName];
    parsedZones = typeof zones === 'string' ? JSON.parse(zones).join(',') : zones.join(',');
  } else {
    const zones = Cookies.get(cookieName) || DEFAULT_COOKIE_VALUES[tenant][cookieName];
    parsedZones = zones;
  }
  return parsedZones;
};

export const getPoliticalId = (tenant: string) => {
  let pId = '';
  const bu = getBUFromTenant(tenant);
  const cookieName = COOKIE_NAMES[bu].POLITICAL_ID;
  if (bu === BUSINESS_UNITS.FA) {
    pId = Cookies.get(cookieName) || DEFAULT_COOKIE_VALUES[tenant][COOKIE_NAMES[bu].POLITICAL_ID];
  }

  return pId;
};

export const getPriceGroupId = (tenant: string) => {
  let pgId = '';
  const bu = getBUFromTenant(tenant);
  const cookieName = COOKIE_NAMES[bu].PRICE_GROUP_ID;
  if (bu === BUSINESS_UNITS.FA) {
    pgId =
      Cookies.get(cookieName) || DEFAULT_COOKIE_VALUES[tenant][COOKIE_NAMES[bu].PRICE_GROUP_ID];
  }

  return pgId;
};

export const getCookies = (tenant: string) => {
  const zones = getZones(tenant);
  const pId = getPoliticalId(tenant);
  const pgId = getPriceGroupId(tenant);

  return {
    zones,
    pId,
    pgId,
  };
};
