import { BUSINESS_UNITS } from '../constants/constants';
import { getCookies } from '../cookies';

export const getBUFromTenant = (tenant: string) => {
  const { FA, SO } = BUSINESS_UNITS;

  return tenant.toUpperCase().includes(FA) ? FA : SO;
};

export const getSearchParameters = (tenant) => {
  const { pId, pgId, zones } = getCookies(tenant);
  const searchParams = {
    ...(pId && { pid: pId }),
    ...(pgId && { pgid: pgId }),
    ...(zones && { zones }),
  };

  return searchParams;
};
