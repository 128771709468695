import { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getCurrentPosition, setPosition } from '../../redux/StepperSlice';
import { StepperMobileStyled } from './styles';
import { StepperMobile } from '@digital-retail/journey-ui-kit';
import { views } from '../CustomRouter/views';

const Stepper = () => {
  const dispatch = useAppDispatch();
  const position = useAppSelector(getCurrentPosition);

  const comeBackTo = (newPosition: number) => {
    dispatch(setPosition(newPosition));
  };

  return (
    <StepperMobileStyled data-testid="stepper-mobile">
      <StepperMobile
        currentStep={position + 1}
        labels={views.map((item) => item.title)}
        onClick={(stepIndex: number) => comeBackTo(stepIndex)}
        style={{ margin: 'auto' }}
      />
    </StepperMobileStyled>
  );
};
export default memo(Stepper);
