import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  LabelStyled,
  LoadingStyled,
  OptionNameStyled,
  OptionsListStyled,
  OptionStyled,
  StylePickerDesktopStyled,
  ThumbnailStyled,
  TitleStyled,
} from './styles';
import { Button, Spinner } from '@digital-retail/journey-ui-kit';
import { nextPosition } from '../../../../redux/StepperSlice';
import {
  getCurtainsStatus,
  getCurtainStyles,
  getSelectedCurtainStyle,
  selectCurtainStyle,
} from '../../../../redux/CurtainSelectionSlice';
import { resetStep } from '../../../../redux/CurtainDesignSelectionSlice';
import ActionsBarDesktop from '../../../../components/ActionsBarDesktop';
import { ICurtainStyle } from '../../../../models/ICurtainFacets';

const StylePickerDesktop: React.FC = () => {
  const dispatch = useAppDispatch();
  const listCurtains = useAppSelector(getCurtainStyles);
  const selectedCurtainStyle = useAppSelector(getSelectedCurtainStyle);
  const curtainsStatus = useAppSelector(getCurtainsStatus);

  const handleClickConfirmCurtainSelection = () => {
    dispatch(nextPosition());
  };

  const handleClick = (curtain: ICurtainStyle) => {
    dispatch(selectCurtainStyle(curtain));
    dispatch(resetStep());
  };

  return (
    <StylePickerDesktopStyled data-testid="style-picker-desktop">
      <TitleStyled>Cortinas a medida</TitleStyled>
      <LabelStyled>Selecciona el estilo que prefieres</LabelStyled>
      {curtainsStatus === 'loading' && (
        <LoadingStyled data-testid="loading">
          <Spinner size="md" />
        </LoadingStyled>
      )}
      {curtainsStatus === 'idle' && (
        <OptionsListStyled data-testid="options-list">
          {listCurtains.map((curtain, curtainIndex) => (
            <OptionStyled
              key={curtainIndex}
              className={`${curtain.id === selectedCurtainStyle?.id ? 'active' : ''}`}
              onClick={() => handleClick(curtain)}
            >
              <ThumbnailStyled src={curtain.image.thumb} alt={curtain.label} />
              <OptionNameStyled>{curtain.label}</OptionNameStyled>
            </OptionStyled>
          ))}
        </OptionsListStyled>
      )}
      <ActionsBarDesktop>
        <Button
          data-testid="confirm-curtain-selected"
          onClick={handleClickConfirmCurtainSelection}
          disabled={selectedCurtainStyle === null}
        >
          Continuar
        </Button>
      </ActionsBarDesktop>
    </StylePickerDesktopStyled>
  );
};

export default StylePickerDesktop;
