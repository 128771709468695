import { MeasurementSettingsStyled } from './styles';
import InformationHowToMeasure from '../InformationHowToMeasure';
import MeasurementSettingForm from '../MeasurementSettingsForm';

export const MeasurementSettingsPageMobile = () => {
  return (
    <MeasurementSettingsStyled data-testid="measurement-settings-mobile">
      <MeasurementSettingForm />
      <InformationHowToMeasure />
    </MeasurementSettingsStyled>
  );
};
