import CustomRouter from './components/CustomRouter';
import WelcomePage from './features/WelcomePage';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UiKitProvider } from '@digital-retail/journey-ui-kit';
import { useAppDispatch } from './app/hooks';
import { useEffect } from 'react';
import Layout from './components/Layout';
import { fetchCurtainStylesAsync } from './redux/CurtainSelectionSlice';
import { setDigitalData } from './utils/adobeDTM/digitalData';

import {
  SCRIPT_URL_ADOBE_DTM_SOAR,
  SCRIPT_URL_ADOBE_DTM_SOBR,
  SCRIPT_URL_ADOBE_DTM_SOCL,
  SCRIPT_URL_ADOBE_DTM_SOCO,
  SCRIPT_URL_ADOBE_DTM_SOMX,
  SCRIPT_URL_ADOBE_DTM_SOPE,
  SCRIPT_URL_ADOBE_DTM_SOUY,
  SCRIPT_URL_ADOBE_DTM_FACL,
  SCRIPT_URL_ADOBE_DTM_FACO,
  SCRIPT_URL_ADOBE_DTM_FAPE,
} from './utils/constants/envVariables';
import { setTenant } from './redux/ConfigSlice';

const ADOBE_DTM_SCRIPT_URLS = {
  SOCL: SCRIPT_URL_ADOBE_DTM_SOCL,
  SOAR: SCRIPT_URL_ADOBE_DTM_SOAR,
  SOUY: SCRIPT_URL_ADOBE_DTM_SOUY,
  SOCO: SCRIPT_URL_ADOBE_DTM_SOCO,
  SOPE: SCRIPT_URL_ADOBE_DTM_SOPE,
  SOBR: SCRIPT_URL_ADOBE_DTM_SOBR,
  SOMX: SCRIPT_URL_ADOBE_DTM_SOMX,
  FACL: SCRIPT_URL_ADOBE_DTM_FACL,
  FACO: SCRIPT_URL_ADOBE_DTM_FACO,
  FAPE: SCRIPT_URL_ADOBE_DTM_FAPE,
};

const App = () => {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const tenant = (urlParams.get('tenant') || 'socl').toUpperCase();

  useEffect(() => {
    dispatch(setTenant(tenant));
    dispatch(fetchCurtainStylesAsync({ tenant }));
    setDigitalData();

    const digitalDataScript = document.createElement('script');
    digitalDataScript.type = 'text/javascript';
    digitalDataScript.innerHTML = `window.digitalData = window.digitalData || ${JSON.stringify({
      ecom: {},
      login: {},
      page: {},
      server: {},
    })};`;
    document.head.appendChild(digitalDataScript);

    const adobeDtmScript = document.createElement('script');
    adobeDtmScript.src = ADOBE_DTM_SCRIPT_URLS[tenant];
    adobeDtmScript.async = true;
    document.head.appendChild(adobeDtmScript);

    return () => {
      document.head.removeChild(digitalDataScript);
      document.head.removeChild(adobeDtmScript);
    };
  }, []);

  return (
    <UiKitProvider>
      <Router>
        <Switch>
          <Route path="/stepper" exact>
            <CustomRouter />
          </Route>
          <Route path="/" exact>
            <Layout withStepper={false} sidebar={null}>
              <WelcomePage />
            </Layout>
          </Route>
        </Switch>
      </Router>
    </UiKitProvider>
  );
};

export default App;
