import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { StylePickerMobileStyled, OptionsListStyled, OptionStyled, LoadingStyled } from './styles';
import { PillButton, Spinner } from '@digital-retail/journey-ui-kit';
import ActionsBarMobile, { ActionBarMobile } from '../../../../components/ActionsBarMobile';
import { ReactComponent as RightArrow } from '../../../../assets/icons/right-arrow.svg';
import { nextPosition } from '../../../../redux/StepperSlice';
import {
  getCurtainsStatus,
  getCurtainStyles,
  getSelectedCurtainStyle,
  selectCurtainStyle,
} from '../../../../redux/CurtainSelectionSlice';
import { resetStep } from '../../../../redux/CurtainDesignSelectionSlice';
import { ICurtainStyle } from '../../../../models/ICurtainFacets';

const StylePickerMobile = () => {
  const dispatch = useAppDispatch();
  const listCurtains = useAppSelector(getCurtainStyles);
  const selectedCurtainStyle = useAppSelector(getSelectedCurtainStyle);
  const curtainsStatus = useAppSelector(getCurtainsStatus);

  const handleClickConfirmCurtainSelection = () => {
    dispatch(nextPosition());
  };

  const handleClick = (curtain: ICurtainStyle) => {
    dispatch(selectCurtainStyle(curtain));
    dispatch(resetStep());
  };

  return (
    <StylePickerMobileStyled data-testid="style-picker-mobile">
      {curtainsStatus === 'loading' && (
        <LoadingStyled data-testid="loading">
          <Spinner size="md" />
        </LoadingStyled>
      )}
      {curtainsStatus === 'idle' && (
        <OptionsListStyled data-testid="options-list">
          {listCurtains.map((curtain, curtainIndex) => (
            <OptionStyled
              key={curtainIndex}
              className={`${
                selectedCurtainStyle && curtain.id === selectedCurtainStyle.id ? 'active' : ''
              }`}
            >
              <PillButton
                active={selectedCurtainStyle && curtain.id === selectedCurtainStyle.id}
                onClick={() => handleClick(curtain)}
                size="sm"
              >
                {curtain?.label}
              </PillButton>
            </OptionStyled>
          ))}
        </OptionsListStyled>
      )}
      <ActionsBarMobile>
        <ActionBarMobile>
          <PillButton
            variant="ghost"
            data-testid="forward"
            onClick={handleClickConfirmCurtainSelection}
            iconPosition="end"
            Icon={RightArrow}
            disabled={!selectedCurtainStyle}
          >
            Confirmar
          </PillButton>
        </ActionBarMobile>
      </ActionsBarMobile>
    </StylePickerMobileStyled>
  );
};

export default StylePickerMobile;
