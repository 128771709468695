/* istanbul ignore file */
interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

/**
 * Custom Fetch
 *
 * @param path path de la fetch
 * @param params opciones de configuración
 *
 * @example
 * interface Todo {
 *   userId: number;
 *   id: number;
 *   title: string;
 *   completed: boolean;
 * }
 *
 * let response: HttpResponse<Todo[]>;
 *
 * try {
 *   response = await customFetch<Todo[]>(
 *     "https://jsonplaceholder.typicode.com/todos"
 *   );
 *   console.log("response", response);
 * } catch (response) {
 *   console.log("Error", response);
 * }
 */
export default async function customFetch<T>(
  path: RequestInfo,
  params?: any
): Promise<HttpResponse<T>> {
  const response: HttpResponse<T> = await fetch(path, params);

  try {
    response.parsedBody = await response.json();
  } catch (ex) {
    throw new Error(response.statusText);
  }
  return response;
}
