import React, { memo, useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';
import { getCurtainDesignSelectionSubStep } from '../../redux/CurtainDesignSelectionSlice';
import {
  getSelectedCurtainDesign,
  getSelectedCurtainStyle,
  getSelectedCurtainCollection,
} from '../../redux/CurtainSelectionSlice';
import defaultImageDesktop from '../../assets/images/WelcomePage/cortina-inicio-desktop.jpg';
import defaultImageMobile from '../../assets/images/WelcomePage/cortina-inicio-mobile.jpg';
import {
  CurtainImageWrapperStyled,
  DescriptionStyled,
  ImageStyled,
  InformationBoxStyled,
  NameStyled,
} from './styles';

type CurtainImageProps = {
  size: 'desktop' | 'mobile';
  currentPositionStep: number;
};

const CurtainImage: React.FC<CurtainImageProps> = ({ size, currentPositionStep }) => {
  const currentSubStep = useAppSelector(getCurtainDesignSelectionSubStep);
  const selectedCurtainStyle = useAppSelector(getSelectedCurtainStyle);
  const selectedCurtainCollection = useAppSelector(getSelectedCurtainCollection);
  const selectedCurtainDesign = useAppSelector(getSelectedCurtainDesign);

  const DEFAULT_IMAGE = {
    image: {
      desktop: defaultImageDesktop,
      mobile: defaultImageMobile,
    },
  };

  const STYLE_CONTENT = selectedCurtainStyle === null ? DEFAULT_IMAGE : selectedCurtainStyle;
  const DESIGN_CONTENT = {
    curtainDesignSelection:
      selectedCurtainDesign !== null ? selectedCurtainDesign : selectedCurtainStyle,
    curtainCollectionSelection:
      selectedCurtainCollection !== null ? selectedCurtainCollection : selectedCurtainDesign,
  };

  const currentImage =
    currentPositionStep === 0
      ? STYLE_CONTENT.image[size]
      : DESIGN_CONTENT[currentSubStep]?.image[size];
  const currentData =
    currentPositionStep === 0 ? selectedCurtainStyle : DESIGN_CONTENT[currentSubStep];

  useEffect(() => {
    new Image().src = DEFAULT_IMAGE.image[size];
  }, []);

  return (
    <CurtainImageWrapperStyled>
      <ImageStyled src={currentImage} />
      {selectedCurtainStyle != null && (
        <InformationBoxStyled>
          <NameStyled>
            {currentData?.label[0].toUpperCase()}
            {currentData?.label.substring(1)}
          </NameStyled>
          <DescriptionStyled>{currentData?.description}</DescriptionStyled>
        </InformationBoxStyled>
      )}
    </CurtainImageWrapperStyled>
  );
};

export default memo(CurtainImage);
