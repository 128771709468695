import { useEffect } from 'react';
import { PillButton, Spinner } from '@digital-retail/journey-ui-kit';
import ActionsBarMobile, { ActionBarMobile } from '../../../../components/ActionsBarMobile';
import { ReactComponent as RightArrow } from '../../../../assets/icons/right-arrow.svg';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg';
import { OptionsListStyled, CurtainVariantPickerMobileStyled, LoadingStyled } from './styles';
import { setStep } from '../../../../redux/CurtainDesignSelectionSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { nextPosition } from '../../../../redux/StepperSlice';
import { COLORS } from '../../../../utils/constants/colors';
import {
  fetchCurtainCollectionsAsync,
  getCurtainCollections,
  getCurtainCollectionStatus,
  getSelectedCurtainCollection,
  selectCurtainCollection,
} from '../../../../redux/CurtainSelectionSlice';
import { setDigitalData } from '../../../../utils/adobeDTM/digitalData';
import { getTenant } from '../../../../redux/ConfigSlice';
import { ICurtainCollection } from '../../../../models/ICurtainFacets';

const CurtainVariantPickerMobile: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedCurtainCollection = useAppSelector(getSelectedCurtainCollection);
  const collections = useAppSelector(getCurtainCollections);
  const curtainsCollectionStatus = useAppSelector(getCurtainCollectionStatus);
  const tenant = useAppSelector(getTenant);

  useEffect(() => {
    setDigitalData();
    if (collections.length === 0) {
      dispatch(fetchCurtainCollectionsAsync({ tenant }));
    }
  }, []);

  const handleClick = (selectedCollection: ICurtainCollection) => {
    dispatch(selectCurtainCollection(selectedCollection));
  };

  const handleClickConfirmCurtainCollection = () => {
    dispatch(nextPosition());
  };

  const handleClickGoToTypeSelection = () => {
    dispatch(setStep('curtainDesignSelection'));
  };

  return (
    <CurtainVariantPickerMobileStyled data-testid="curtain-variant-picker-mobile">
      {curtainsCollectionStatus === 'loading' && (
        <LoadingStyled data-testid="loading">
          <Spinner size="md" />
        </LoadingStyled>
      )}
      {curtainsCollectionStatus === 'idle' && (
        <OptionsListStyled data-testid="options-list">
          {collections.map((collection) => (
            <PillButton
              key={collection.id}
              size="sm"
              active={collection.id === selectedCurtainCollection?.id}
              onClick={() => handleClick(collection)}
            >
              {collection.label[0].toUpperCase()}
              {collection.label.substring(1)}
            </PillButton>
          ))}
        </OptionsListStyled>
      )}
      <ActionsBarMobile>
        <ActionBarMobile>
          <PillButton
            onClick={handleClickConfirmCurtainCollection}
            data-testid="confirm-curtain-variant-selected"
            variant="ghost"
            iconPosition="end"
            Icon={RightArrow}
            disabled={!selectedCurtainCollection}
          >
            Confirmar
          </PillButton>
        </ActionBarMobile>
        <ActionBarMobile>
          <PillButton
            onClick={handleClickGoToTypeSelection}
            data-testid="back"
            variant="ghost"
            iconPosition="start"
            Icon={LeftArrow}
            style={{
              color: COLORS.gray20,
            }}
          >
            Selecciona un diseño
          </PillButton>
        </ActionBarMobile>
      </ActionsBarMobile>
    </CurtainVariantPickerMobileStyled>
  );
};

export default CurtainVariantPickerMobile;
