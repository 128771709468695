/* istanbul ignore file */
import URL from '../url';
import customFetch from '../../utils/customFetch';
import { FetchDesignsProps } from '../../models/IFetchApiFacetsProps';
import { ICurtainDesign, IFetchResponse } from '../../models/ICurtainFacets';

export const fetchDesignOptions = async (props: FetchDesignsProps) => {
  const { url, params } = URL.fetchCurtainDesigns(props);
  const response = (await customFetch<IFetchResponse<ICurtainDesign[]>>(url, params)).parsedBody
    ?.data;
  return response ? response : [];
};
