type MinAndMaxValidationProps = {
  valueInFloat: number;
  min: number;
  max: number;
};
export const minAndMaxValidation = ({ valueInFloat, min, max }: MinAndMaxValidationProps) => {
  if (isNaN(valueInFloat)) {
    return true;
  }

  if (min <= valueInFloat && valueInFloat <= max) {
    return true;
  }
  return false;
};

type FormValidationProps = {
  windowHeight: string | null;
  windowWidth: string | null;
  windowHeightWithError: boolean;
  windowWidthWithError: boolean;
};
export const formValidation = ({
  windowHeight,
  windowWidth,
  windowHeightWithError,
  windowWidthWithError,
}: FormValidationProps) => {
  return (
    windowHeight !== null &&
    windowHeight !== '' &&
    windowWidth !== null &&
    windowWidth !== '' &&
    !windowHeightWithError &&
    !windowWidthWithError
  );
};
