import styled from 'styled-components';
import { mediaQuery } from '../../../utils';

export const CurtainDesignSelectionPageStyled = styled.div`
  ${mediaQuery.tabletToUp(`
    max-height: 520px;
    padding: 20px;
    width: calc(425px - (20px * 2));
    height: calc(520px - (20px * 2));
  `)}
`;
