/* istanbul ignore file */

const {
  REACT_APP_SCRIPT_URL_ADOBE_DTM_SOCL: SCRIPT_URL_ADOBE_DTM_SOCL,
  REACT_APP_SCRIPT_URL_ADOBE_DTM_SOAR: SCRIPT_URL_ADOBE_DTM_SOAR,
  REACT_APP_SCRIPT_URL_ADOBE_DTM_SOUY: SCRIPT_URL_ADOBE_DTM_SOUY,
  REACT_APP_SCRIPT_URL_ADOBE_DTM_SOCO: SCRIPT_URL_ADOBE_DTM_SOCO,
  REACT_APP_SCRIPT_URL_ADOBE_DTM_SOPE: SCRIPT_URL_ADOBE_DTM_SOPE,
  REACT_APP_SCRIPT_URL_ADOBE_DTM_SOBR: SCRIPT_URL_ADOBE_DTM_SOBR,
  REACT_APP_SCRIPT_URL_ADOBE_DTM_SOMX: SCRIPT_URL_ADOBE_DTM_SOMX,
  REACT_APP_SCRIPT_URL_ADOBE_DTM_FACL: SCRIPT_URL_ADOBE_DTM_FACL,
  REACT_APP_SCRIPT_URL_ADOBE_DTM_FACO: SCRIPT_URL_ADOBE_DTM_FACO,
  REACT_APP_SCRIPT_URL_ADOBE_DTM_FAPE: SCRIPT_URL_ADOBE_DTM_FAPE,

  REACT_APP_BFF_API: BFF_API,
} = process.env;

export {
  SCRIPT_URL_ADOBE_DTM_SOCL,
  SCRIPT_URL_ADOBE_DTM_SOAR,
  SCRIPT_URL_ADOBE_DTM_SOUY,
  SCRIPT_URL_ADOBE_DTM_SOCO,
  SCRIPT_URL_ADOBE_DTM_SOPE,
  SCRIPT_URL_ADOBE_DTM_SOBR,
  SCRIPT_URL_ADOBE_DTM_SOMX,
  SCRIPT_URL_ADOBE_DTM_FACL,
  SCRIPT_URL_ADOBE_DTM_FACO,
  SCRIPT_URL_ADOBE_DTM_FAPE,
  BFF_API,
};
