import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { getSearchParameters } from '../../../utils/helpers';

export const generateSearchParams = (obj: any, tenant: string) => {
  const additionalSearchParameters = getSearchParameters(tenant);
  const newObject = { ...obj, ...additionalSearchParameters };
  const searchParams = new URLSearchParams(omitBy(newObject, isEmpty));

  return searchParams.toString();
};
