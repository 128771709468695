/* istanbul ignore file */
import URL from '../url';
import customFetch from '../../utils/customFetch';
import { FetchMeasurementsProps } from '../../models/IFetchApiFacetsProps';
import { ICurtainMeasurement, IFetchResponse } from '../../models/ICurtainFacets';

export const fetchMeasurementLimits = async (props: FetchMeasurementsProps) => {
  const { url, params } = URL.fetchCurtainMeasurements(props);
  const response = (await customFetch<IFetchResponse<ICurtainMeasurement>>(url, params)).parsedBody
    ?.data;
  return response
    ? response
    : {
        windowHeight: {
          min: 0,
          max: 0,
        },
        windowWidth: {
          min: 0,
          max: 0,
        },
        widthValues: [0]
      };
};
