import React, { ReactElement, useEffect } from 'react';
import { CurtainDesignSelectionPageStyled } from './styles';
import { useAppSelector } from '../../app/hooks';
import CurtainDesignPickerMobile from './components/CurtainDesignPickerMobile';
import CurtainVariantPickerMobile from './components/CurtainVariantPickerMobile';
import {
  getCurtainDesignSelectionSubStep,
  ICurtainDesignSelectionSubStep,
} from '../../redux/CurtainDesignSelectionSlice';
import MediaComponent from '../../components/MediaComponent';
import CurtainDesignPickerDesktop from './components/CurtainDesignPickerDesktop';
import CurtainVariantPickerDesktop from './components/CurtainVariantPickerDesktop';
import { setDigitalData } from '../../utils/adobeDTM/digitalData';

type Content = {
  // eslint-disable-next-line no-unused-vars
  [key in ICurtainDesignSelectionSubStep]: ReactElement;
};

const CurtainDesignSelectionPage: React.FC = () => {
  useEffect(() => {
    setDigitalData();
  }, []);

  const currentCurtainDesignSelectionSubStep = useAppSelector(getCurtainDesignSelectionSubStep);
  const MOBILE_CONTENT: Content = {
    curtainDesignSelection: <CurtainDesignPickerMobile />,
    curtainCollectionSelection: <CurtainVariantPickerMobile />,
  };

  const DESKTOP_CONTENT: Content = {
    curtainDesignSelection: <CurtainDesignPickerDesktop />,
    curtainCollectionSelection: <CurtainVariantPickerDesktop />,
  };

  return (
    <CurtainDesignSelectionPageStyled data-testid="curtain-design-selection-sidebar">
      <MediaComponent from="mobile" to="tablet">
        {MOBILE_CONTENT[currentCurtainDesignSelectionSubStep]}
      </MediaComponent>
      <MediaComponent from="tablet">
        {DESKTOP_CONTENT[currentCurtainDesignSelectionSubStep]}
      </MediaComponent>
    </CurtainDesignSelectionPageStyled>
  );
};

export default CurtainDesignSelectionPage;
