import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils';
import { COLORS } from '../../../../../utils/constants/colors';

export const MeasurementSettingFormStyled = styled.div``;

export const WindowHeightStyled = styled.div`
  grid-area: windowHeight;
  width: 150px;
`;

export const WindowWidthStyled = styled.div`
  grid-area: windowWidth;
  width: 150px;
`;

export const LabelStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
  width: max-content;

  span {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    padding-left: 2px;
  }
`;

export const IndicationInputStyled = styled.p`
  color: #333333;
  font-size: 12px;
  line-height: 16px;

  text-align: left;
  margin: 1px 0;
`;

export const TitleStyled = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  color: #333333;
  margin-bottom: 20px;

  ${mediaQuery.mobileOnly(`
    font-size: 17px;
    line-height: 20px;
  `)}
`;

export const ImageStyled = styled.img`
  margin-bottom: 5px;

  ${mediaQuery.mobileOnly(`
    display: block;
    margin-left: auto;
    margin-right: auto;
  `)}
`;

export const FieldsetStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'windowHeight windowWidth';
  gap: 20px;
  margin-bottom: 20px;

  ${mediaQuery.mobileOnly(`
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'windowHeight'
      'windowWidth';
  `)}
`;

export const LoadingStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 20px;
`;

export const HelperStyled = styled.div`
  font-size: 14px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 15px;
  width: fit-content;

  span {
    color: ${COLORS.primary};
  }

  & > * {
    cursor: pointer;
  }

  margin-bottom: 20px;
`;
