import styled from 'styled-components';

const GRADIENT_HEIGHT = '20px';

export const BoxStyled = styled.div`
  overflow: auto;
  position: relative;
`;

export const BoxContentStyled = styled.div`
  margin-top: calc(${GRADIENT_HEIGHT} * -1);
  margin-bottom: calc(${GRADIENT_HEIGHT} * -1);
`;

export const GradientStyled = styled.div`
  background: rgb(255, 255, 255);
  width: 100%;
  position: sticky;
  transition: all 0.25s ease-out;
  left: 0;
  z-index: 100;
  height: ${GRADIENT_HEIGHT};
  opacity: 0;

  &.show {
    opacity: 1;
  }

  &.top {
    top: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &.bottom {
    bottom: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
`;
