import styled from 'styled-components';

export const CurtainVariantPickerMobileStyled = styled.div``;

export const OptionsListStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  padding: 10px 15px;
  margin: -5px;

  & > * {
    margin: 5px;
  }
`;

export const LoadingStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 9px;
`;
