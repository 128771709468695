import React from 'react';
import ActionsBarMobile, { ActionBarMobile } from '../../../../components/ActionsBarMobile';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { ReactComponent as RightArrow } from '../../../../assets/icons/right-arrow.svg';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg';
import { COLORS } from '../../../../utils/constants/colors';
import { previousPosition, setPosition } from '../../../../redux/StepperSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  fetchRedirectStoreAsync,
  getFormIsValid,
} from '../../../../redux/MeasurementSettingsSlice';
import { setDigitalData } from '../../../../utils/adobeDTM/digitalData';
import { getTenant } from '../../../../redux/ConfigSlice';
import { getCurtainColors } from '../../../../redux/CurtainSelectionSlice';

const MeasurementSettingsActionBarMobile: React.FC = () => {
  const dispatch = useAppDispatch();
  const formIsValid = useAppSelector(getFormIsValid);
  const tenant = useAppSelector(getTenant);
  const colors = useAppSelector(getCurtainColors);

  const handleClickBackward = () => {
    if (colors.length > 0) {
      dispatch(previousPosition());
    } else {
      dispatch(setPosition(1));
    }
  };

  const handleClickForward = () => {
    setDigitalData();
    dispatch(fetchRedirectStoreAsync({ tenant }));
  };

  return (
    <div data-testid="measurement-settings-action-bar-mobile">
      <ActionsBarMobile>
        <ActionBarMobile>
          <PillButton
            variant="ghost"
            iconPosition="end"
            data-testid="forward"
            disabled={!formIsValid}
            Icon={RightArrow}
            onClick={handleClickForward}
          >
            Confirmar
          </PillButton>
        </ActionBarMobile>
        <ActionBarMobile>
          <PillButton
            variant="ghost"
            iconPosition="start"
            Icon={LeftArrow}
            onClick={handleClickBackward}
            data-testid="backward"
            style={{
              color: COLORS.gray20,
            }}
          >
            Elige tu {colors.length > 0 ? 'color' : 'diseño'}
          </PillButton>
        </ActionBarMobile>
      </ActionsBarMobile>
    </div>
  );
};

export default MeasurementSettingsActionBarMobile;
