export const BUSINESS_UNITS = {
  FA: 'FA',
  SO: 'SO',
};

export const TENANTS = {
  SOCL: 'SOCL',
  SOPE: 'SOPE',
  SOCO: 'SOCO',
  SOMX: 'SOMX',
  SOBR: 'SOBR',
  SOAR: 'SOAR',
  SOUY: 'SOUY',
  FACL: 'FACL',
  FACO: 'FACO',
  FAPE: 'FAPE',
};

export const COOKIE_NAMES = {
  [BUSINESS_UNITS.FA]: {
    ZONES: 'zones',
    POLITICAL_ID: 'politicalId',
    PRICE_GROUP_ID: 'priceGroupId',
  },
  [BUSINESS_UNITS.SO]: {
    ZONES: 'comuna',
    POLITICAL_ID: '',
    PRICE_GROUP_ID: '',
  },
};

export const DEFAULT_COOKIE_VALUES = {
  [TENANTS.FACL]: {
    [COOKIE_NAMES[BUSINESS_UNITS.FA].ZONES]: [
      'FALABELLA_FBY_BT_SDD',
      'PCL2829',
      'REVERSE_RM_REDPROPIA',
      'PCL2998',
      'PCL3128',
      'PCL2808',
      '1234',
      'PCL1223',
      'PCL2802',
      'PCL2826',
      'PCL3019',
      'PCL2281',
      'PCL3009',
      'HUB_SALIDA_DIRECTA_RM',
      'PCL2120',
      'PCL3043',
      'PCL3025',
      'BLUE_RM_URBANO',
      'PCL115',
      '3045',
      'PCL1486',
      'PCL2980',
      'PCL1736',
      'PCL1923',
      'PCL2981',
      'PCL2792',
      'PCL3006',
      'BX_R13_BASE',
      'PCL226',
      'PCL2847',
      'PCL2901',
      'PCL2827',
      'PCL2979',
      'PCL1389',
      'PCL2843',
      'ZL_CERRILLOS',
      'PCL1135',
      'PCL2977',
      'SCD9039_FLEX',
      'PCL105',
      'FBY_RM_M',
      'PCL2380',
      'PCL2442',
      '2020',
      'PCL1186',
      'PCL2864',
      'FALABELLA_FBY_SDD',
      'PCL2269',
      'PCL2846',
      '13',
      'PCL861',
      'PCL3015',
      'FEDEX_RM_URB',
      'PCL2520',
      'LOSC',
      'PCL540',
      'CHILEXPRESS_8',
      'PCL1839',
      'FBY_BT_SALIDA_DIRECTA',
      'PCL2288',
      'PCL2978',
      'PCL2862',
      'PCL2803',
      'PCL2890',
      'PCL2838',
      'RM',
      'PCL108',
      'PCL94',
      'CHILE_INTERNATIONAL',
      'PCL1364',
      'PCL2801',
      'PCL3011',
      'PCL3017',
      '13_MAIPU',
      'PCL2830',
      'PCL3026',
      'PCL2845',
      'PCL2982',
      'PCL109',
      'PCL3136',
      '130617',
      'PCL25',
      'PCL2441',
      'PCL184',
      'PCL3012',
      'PCL1336',
      'STARKEN_R13',
      'PCL3041',
      'PCL2825',
      'PCL2511',
      'PCL3042',
    ],
    [COOKIE_NAMES[BUSINESS_UNITS.FA].POLITICAL_ID]: '15c37b0b-a392-41a9-8b3b-978376c700d5',
    [COOKIE_NAMES[BUSINESS_UNITS.FA].PRICE_GROUP_ID]: 96,
  },
  [TENANTS.FAPE]: {
    [COOKIE_NAMES[BUSINESS_UNITS.FA].ZONES]: [
      'IBIS_51',
      'PPE3342',
      'PPE3361',
      'PPE1112',
      'PPE3384',
      '912_LIMA_2',
      'PPE1280',
      '150000',
      'PPE4',
      '912_LIMA_1',
      'PPE1279',
      '150101',
      'PPE344',
      'PPE3059',
      'PPE2492',
      'IMP_2',
      'PPE3331',
      'PPE3357',
      'PPE1091',
      'PERF_TEST',
      'PPE1653',
      'PPE2486',
      'OLVAA_81',
      'PPE2815',
      'IMP_1',
      'PPE3164',
      'PPE2918',
      'URBANO_83',
      'PPE2429',
      'PPE3152',
      'PPE3479',
      'PPE3483',
      'PPE3394',
      'LIMA_URB1_DIRECTO',
      'PPE2511',
      'IBIS_19',
      'PPE1382',
      'IBIS_3PL_83',
      'PPE3248',
    ],
    [COOKIE_NAMES[BUSINESS_UNITS.FA].POLITICAL_ID]: '799c102f-9b4c-44be-a421-23e366a63b82',
    [COOKIE_NAMES[BUSINESS_UNITS.FA].PRICE_GROUP_ID]: 2,
  },
  [TENANTS.SOMX]: {
    [COOKIE_NAMES[BUSINESS_UNITS.SO].ZONES]: '',
    [COOKIE_NAMES[BUSINESS_UNITS.SO].POLITICAL_ID]: '',
    [COOKIE_NAMES[BUSINESS_UNITS.SO].PRICE_GROUP_ID]: '',
  },
  [TENANTS.SOCL]: {
    [COOKIE_NAMES[BUSINESS_UNITS.SO].ZONES]: '',
    [COOKIE_NAMES[BUSINESS_UNITS.SO].POLITICAL_ID]: '',
    [COOKIE_NAMES[BUSINESS_UNITS.SO].PRICE_GROUP_ID]: '',
  },
};
