import { useHistory, useLocation } from 'react-router-dom';

import {
  BodyStyled,
  DescriptionStyled,
  HeaderStyled,
  JumbotronStyled,
  StartButtonStyled,
} from './styles';
import { Button } from '@digital-retail/journey-ui-kit';
import { WelcomePageStyled } from './styles';
import ServiceModal from './components/ServiceModal';
import cortinaInicioDesktop from '../../assets/images/WelcomePage/cortina-inicio-desktop.jpg';
import { tracking } from '../../utils/adobeDTM/satellite';
import { ServiceDescriptionHighlightedStyled, ServiceDescriptionStyled, ServiceStyled, ServicesWrapperStyled } from './components/ServiceModal/styles';
import { MOCK_SERVICES } from '../../__mocks__/services';

const WelcomePage: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();

  const handleClickStart = () => {
    history.push(`/stepper${search}`);
    tracking('empecemos');
  };

  const renderServices = () => {
    return (
      <ServicesWrapperStyled>
        {MOCK_SERVICES.map(({ Image, ...service }, serviceIndex) => (
          <ServiceStyled key={serviceIndex}>
            <Image />
            <ServiceDescriptionStyled>{service.description}</ServiceDescriptionStyled>
            <ServiceDescriptionHighlightedStyled>{service.hightlighed}</ServiceDescriptionHighlightedStyled>
          </ServiceStyled>
        ))}
      </ServicesWrapperStyled>
    );
  };

  return (
    <WelcomePageStyled data-testid="welcome-page">
      <JumbotronStyled src={cortinaInicioDesktop} />
      <HeaderStyled>
        Compra<strong> cortinas premium</strong> a medida que
        <strong> incluyen el servicio de rectificación e instalación.</strong>
      </HeaderStyled>
      <BodyStyled>
      <DescriptionStyled>
            Te ayudamos en el paso a paso con explicaciones sencillas para que sepas qué considerar a
          la hora de elegir tus cortinas a medidas.
      </DescriptionStyled>       
          {renderServices()}
        <StartButtonStyled>
          <Button data-testid="start-button" size="md" onClick={handleClickStart}>
            ¡Empecemos!
          </Button>
        </StartButtonStyled>
      </BodyStyled>
      <ServiceModal />
    </WelcomePageStyled>
  );
};

export default WelcomePage;
