import { Button } from '@digital-retail/journey-ui-kit';
import {
  getCurtainDesigns,
  getSelectedCurtainColor,
} from '../../../../redux/CurtainSelectionSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { nextPosition, previousPosition, setPosition } from '../../../../redux/StepperSlice';
import ActionsBarDesktop from '../../../../components/ActionsBarDesktop';
import { PillButton } from '@digital-retail/journey-ui-kit';
import { COLORS } from '../../../../utils/constants/colors';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/left-arrow.svg';

const CurtainColorSelectionActionsBarDesktop = () => {
  const dispatch = useAppDispatch();
  const curtainColorSelected = useAppSelector(getSelectedCurtainColor);
  const designs = useAppSelector(getCurtainDesigns);

  const handleClickForward = () => {
    dispatch(nextPosition());
  };

  const handleClickBackward = () => {
    if (designs.length === 0) {
      dispatch(setPosition(0));
    } else {
      dispatch(previousPosition());
    }
  };

  return (
    <div data-testid="curtain-color-selection-actions-bar-desktop">
      <ActionsBarDesktop>
        <Button
          data-testid="forward"
          onClick={handleClickForward}
          disabled={curtainColorSelected === null}
        >
          Continuar
        </Button>
        <PillButton
          onClick={handleClickBackward}
          data-testid="backward"
          variant="ghost"
          iconPosition="start"
          Icon={LeftArrow}
          iconStyle={{
            fill: COLORS.primary,
          }}
          style={{
            color: COLORS.primary,
            marginTop: 12,
            paddingLeft: 0,
          }}
        >
          Selecciona un {designs.length === 0 ? 'estilo' : 'diseño'}
        </PillButton>
      </ActionsBarDesktop>
    </div>
  );
};

export default CurtainColorSelectionActionsBarDesktop;
