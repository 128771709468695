import { useEffect } from 'react';
import { CurtainColorSelectionPageStyled } from './styles';
import CurtainColorPicker from './components/CurtainColorPicker';
import { setDigitalData } from '../../utils/adobeDTM/digitalData';

const CurtainColorSelectionPage: React.FC = () => {
  useEffect(() => {
    setDigitalData();
  }, []);

  return (
    <CurtainColorSelectionPageStyled data-testid="curtain-color-selection-sidebar">
      <CurtainColorPicker />
    </CurtainColorSelectionPageStyled>
  );
};

export default CurtainColorSelectionPage;
