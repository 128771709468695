/* istanbul ignore file */
import URL from '../url';
import customFetch from '../../utils/customFetch';
import { FetchCollectionsProps } from '../../models/IFetchApiFacetsProps';
import { ICurtainCollection, IFetchResponse } from '../../models/ICurtainFacets';

export const fetchCollectionOptions = async (props: FetchCollectionsProps) => {
  const { url, params } = URL.fetchCurtainCollections(props);
  const response = (await customFetch<IFetchResponse<ICurtainCollection[]>>(url, params)).parsedBody
    ?.data;
  return response ? response : [];
};
