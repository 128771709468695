import { IView } from '../../../models/IView';
import StyleSelectionSidebar from '../../../features/CurtainStyleSelectionPage';
import CurtainDesignSelectionSidebar from '../../../features/CurtainDesignSelectionPage';
import CurtainColorSelectionSidebar from '../../../features/CurtainColorSelectionPage';
import MeasurementSettingsSidebar from '../../../features/MeasurementSettingsPage';

export const views: IView[] = [
  {
    title: 'Selecciona un estilo',
    sidebar: StyleSelectionSidebar,
  },
  {
    title: 'Elige tu diseño',
    sidebar: CurtainDesignSelectionSidebar,
  },
  {
    title: 'Elige tu color',
    sidebar: CurtainColorSelectionSidebar,
  },
  {
    title: 'Ingresa las medidas',
    sidebar: MeasurementSettingsSidebar,
  },
];
