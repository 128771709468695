import styled from 'styled-components';
import { mediaQuery } from '../../../../../utils';

export const ServiceModalStyled = styled.div``;

const NUMBER_OF_SERVICES_PER_COLUMNS =3;

export const ServicesWrapperStyled = styled.div`
  padding: 10px;
  border: 0.5px solid #8C8C8C;
  border-radius: 4px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;


  ${mediaQuery.tabletToUp(`
  max-width: 391px;


    & > * { 
      padding-right: 12px;
    }
  `)}

  & :last-child {
    padding-right: 0px;
  }
`;

export const ServiceStyled = styled.div`
  list-style-type: none;
  text-align: center;
  max-width: calc((100% - (6px * ${NUMBER_OF_SERVICES_PER_COLUMNS})) / ${NUMBER_OF_SERVICES_PER_COLUMNS});
  padding-right: 8px;


  & p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0px;
  }
  & svg {
    width: 24px;
    height: 24px;
  }
  span {
    font-size: 12px;
    line-height: 16px;
  }
  ${mediaQuery.tabletToUp(`
    max-width: 111px;
  `)}
`;

export const ServiceDescriptionStyled = styled.p`
  margin-top: 0;
`;

export const ServiceDescriptionHighlightedStyled = styled.span`
font-weight: 700;
`;

export const InformationStyled = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin: 26px 0 20px 10px;
`;
