import { Modal } from '@digital-retail/journey-ui-kit';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getServiceModalIsShowing, hideServiceModal } from '../../../../redux/WelcomePageSlice';
import {
  ServiceModalStyled,
  InformationStyled,
  ServicesWrapperStyled,
  ServiceStyled,
  ServiceDescriptionStyled,
} from './styles';
import { MOCK_SERVICES } from '../../../../__mocks__/services';

const ServiceModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(getServiceModalIsShowing);

  const handleClickCloseModal = () => {
    dispatch(hideServiceModal());
  };

  const renderServices = () => {
    return (
      <ServicesWrapperStyled>
        {MOCK_SERVICES.map(({ Image, ...service }, serviceIndex) => (
          <ServiceStyled key={serviceIndex}>
            <Image />
            <ServiceDescriptionStyled>{service.description}</ServiceDescriptionStyled>
          </ServiceStyled>
        ))}
      </ServicesWrapperStyled>
    );
  };

  return (
    <Modal open={open} size="md" onClose={handleClickCloseModal}>
      <ServiceModalStyled data-testid="service-modal">
        <InformationStyled>
          <b>Instalación de cortinas a medida</b> rectificadas e instaladas
        </InformationStyled>
        <div>{renderServices()}</div>
      </ServiceModalStyled>
    </Modal>
  );
};

export default ServiceModal;
