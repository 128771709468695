import styled from 'styled-components';

export const ActionsBarDesktopStyled = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;

  justify-content: space-between;
  align-content: center;
  align-items: center;
`;
